/* eslint-disable no-async-promise-executor */
import axios from '@/api/axios'

const userService = {
	getNotice(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/notices/${id}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getNoticeDetail(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/notices/${id}/detail`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getNotices() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/notices/for-marketer?order=desc&orderby=createdAt`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getLatestNotices() {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/notices/for-marketer?order=desc&orderby=createdAt&limit=5`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default userService
