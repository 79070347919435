<template>
	<div class="notices-wrapper">
		<v-container>
			<div class="full-width d-flex">
				<div class="title">
					<em class="em">NOTICE</em>
				</div>
			</div>
			<div>
				<ul class="lst_board_tit">
					<li>{{ $t('notice.title') }}</li>
					<li>{{ $t('notice.date') }}</li>
				</ul>
			</div>
			<div>
				<ul class="lst_board">
					<li v-for="(notice, index) in notices" :key="index" class="d-flex">
						<router-link
							class="notice-link"
							:to="{ name: 'notice-detail', params: { noticeId: notice.id } }"
						>
							<div class="link-inner">
								<span class="notice-title">{{ notice.title }}</span>
								<span class="sub-date">
									{{ notice.createdAt | dateFormat }}
								</span>
							</div>
						</router-link>
						<div class="date">
							{{ notice.createdAt | dateFormat }}
						</div>
					</li>
				</ul>
			</div>
		</v-container>
	</div>
</template>
<script>
import store from '@/store'
import { ref } from '@vue/composition-api'

import NoticeService from '@/services/NoticeService'

export default {
	components: {},
	setup() {
		const notices = ref([])

		const getNotices = async () => {
			try {
				notices.value = await NoticeService.getNotices()
			} catch (e) {
				console.error(e)
			}
		}
		getNotices()

		store.dispatch('app/setIsLogoWhite', false)

		return {
			notices,
		}
	},
}
</script>
<style lang="scss" scoped>
ul,
li {
	list-style: none;
}

.notices-wrapper {
	padding-top: 100px;

	.title {
		display: flex;
		justify-content: flex-start;
		color: #060539;
		font-size: 24.5px !important;
		line-height: 38px;
		margin-bottom: 60px;
		width: inherit;
		letter-spacing: 0.0001rem !important;

		.em {
			font-size: 36px;
			line-height: 45px;
			font-weight: 500;
			font-style: normal;
		}
	}

	.lst_board_tit {
		display: table;
		width: 100%;
		table-layout: fixed;
		background-color: #f8f8f9;
		border-bottom: 2px solid hsl(240, 22%, 96%);

		@media (max-width: 1040px) {
			display: none;
		}

		li {
			border: 0;
			display: table-cell;
			padding: 27px 40px 26px;
			color: #666;
			text-align: center;
			vertical-align: middle;
			font-size: 17px;
			line-height: 21px;
			font-weight: 700;
			width: 80%;

			&:nth-child(2) {
				width: 20%;
			}
		}
	}

	.lst_board {
		padding-left: 0;

		li {
			display: table;
			width: 100%;
			height: 101px;
			border-top: 2px solid #f4f4f8;
			table-layout: fixed;
			background-color: #fff;
			box-sizing: border-box;

			@media (max-width: 1040px) {
				border-bottom: 6px solid #f4f4f8;
			}

			&:first-child {
				border-top: 0;
			}

			.sub-date {
				display: none;
				font-size: 18px;
				line-height: 22px;
				color: #999;

				@media (max-width: 1040px) {
					display: block !important;
				}
			}

			.notice-link {
				display: flex;
				align-items: center;
				text-decoration: none;
				padding: 0 40px;
				box-sizing: border-box;
				font-size: 17px;
				line-height: 25px;
				word-break: break-all;
				width: 100%;
				height: 99px;

				@media (max-width: 596px) {
					padding: 0 20px;
				}

				.link-inner {
					display: inline-block;
					vertical-align: middle;
					max-height: 50px;

					span.notice-title {
						display: block;
						overflow: hidden;
						white-space: nowrap;
						text-overflow: ellipsis;
						width: 280px;

						@media (max-width: 280px) {
							width: 246px;
						}
					}
				}

				&:hover {
					.link-inner {
						color: #060539;
						text-decoration: underline;
					}
				}
			}

			.date {
				text-align: center;
				width: 20%;

				@media (max-width: 1040px) {
					display: none;
				}

				&:before {
					display: inline-block;
					height: 100%;
					vertical-align: middle;
					content: '';
				}
			}
		}
	}
}
</style>
